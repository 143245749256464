<template>
    <div>
        <template v-if="getConfig('messages.enabled', true)">
            <v-row v-if="$vuetify.breakpoint.xs">
                <!-- Discussions -->
                <v-col v-if="!selectedConversationId" cols="12" xl="2" xs="12">
                    <v-list shaped color="#f6f7ff">
                        <v-subheader>
                            Discussions

                            <v-spacer />

                            <CreateMessageGroupMenuUser v-if="$store.state.application.user.type === 'buyer'" @created="onConversationCreated" :activeConversations="conversations">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon small> fas fa-plus </v-icon>
                                    </v-btn>
                                </template>
                            </CreateMessageGroupMenuUser>

                            <CreateMessageGroupMenuBuyer v-if="$store.state.application.user.type === 'user'" @created="onConversationCreated" :activeConversations="conversations">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon small> fas fa-plus </v-icon>
                                    </v-btn>
                                </template>
                            </CreateMessageGroupMenuBuyer>
                        </v-subheader>

                        <v-list-item-group v-model="selectedConversationId">
                            <v-list-item v-for="conversation of conversations" :value="conversation.id" :key="conversation.id">
                                <v-list-item-avatar class="white--text text-uppercase" :color="getColorByName(getConversationDisplayName(conversation))" size="32">
                                    {{ getConversationDisplayName(conversation) ? getConversationDisplayName(conversation).slice(0, 1) : '' }}
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="body-2">
                                        <template v-if="getConversationDisplayName(conversation)">
                                            {{ getConversationDisplayName(conversation) }}
                                        </template>
                                        <template v-else>
                                            <i> &lt;Compte Inactif&gt; </i>
                                        </template>
                                    </v-list-item-title>

                                    <v-list-item-subtitle v-if="conversation.message">
                                        <template v-if="conversation.message.isMe">
                                            Vous :
                                        </template>
                                        {{ conversation.message.content }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-badge v-if="conversation.unreadCount > 0" :content="conversation.unreadCount" color="red" inline dot>

                                    </v-badge>
                                </v-list-item-action>

                                <v-list-item-action v-show="false">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn small icon v-on="on">
                                                <v-icon small> fas fa-ellipsis-v </v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <LeaveConversationDialog :conversation="conversation">
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        Quitter la conversation
                                                    </v-list-item>
                                                </template>
                                            </LeaveConversationDialog>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>

                <v-divider vertical />

                <!-- Messages -->
                <v-col v-if="selectedConversationId" id="conversation" class="d-flex flex-column fill-height">
                    <v-row justify="center" @click="selectedConversationId = null;" class="cursor-pointer">
                        <v-col cols="12">
                            <template>
                                <v-btn class="mr-2" icon large>
                                    <v-icon> fas fa-chevron-circle-left </v-icon>
                                </v-btn>

                                {{ this.currentDisplayName }}
                            </template>
                        </v-col>
                    </v-row>

                    <v-list ref="messageList" style="overflow-y: scroll" color="#f6f7ff">
                        <Message v-for="(message, i) of messages" :key="message.id" :messages="messages" :message="message" :index="i" @deleted="onMessageDeleted" @updated="onMessageUpdated" />
                        <template v-if="messages.length === 0">
                            <v-row justify="center" align="center" style="height: 200px" no-gutters>
                                <v-col>
                                    <h3 class="text-center"> Aucun message </h3>
                                </v-col>
                            </v-row>
                        </template>
                    </v-list>

                    <v-divider />

                    <v-row justify="center">
                        <v-col cols="12">
                            <CreateMessageInput @created="onMessageCreated($event)" :conversationId="selectedConversationId" :newRecipients="newRecipients" :firstMessage="messages.length === 0" class="mt-2" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-if="!$vuetify.breakpoint.xs" class="mt-0" no-gutters>
                <!-- Discussions -->
                <v-col cols="3" xl="3">
                    <ProgramAutocomplete v-if="isUser" class="mt-2 px-5" @input="fetchConversations(null, true)" ref="programAutocomplete" v-model="search.programs" label="Programme(s)" multiple noDynamicSearch />

                    <v-list ref="conversationsList" shaped color="#f6f8fc" :style="{ 'height': messageContainerHeight, 'overflow': 'auto' }">
                        <v-subheader>
                            Discussions

                            <v-spacer />

                            <CreateMessageGroupMenuUser v-if="$store.state.application.user.type === 'buyer'" @created="onConversationCreated" :activeConversations="conversations">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon small> fas fa-plus </v-icon>
                                    </v-btn>
                                </template>
                            </CreateMessageGroupMenuUser>

                            <CreateMessageGroupMenuBuyer v-if="$store.state.application.user.type === 'user'" @created="onConversationCreated" :activeConversations="conversations">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon small> fas fa-plus </v-icon>
                                    </v-btn>
                                </template>
                            </CreateMessageGroupMenuBuyer>
                        </v-subheader>

                        <v-list-item-group v-model="selectedConversationId" mandatory>
                            <v-menu v-for="conversation of conversations" :key="conversation.id" open-on-hover offset-x>
                                <template v-slot:activator="{ on }">
                                    <v-list-item :value="conversation.id" v-on="on">
                                        <v-list-item-avatar class="white--text text-uppercase" :color="getColorByName(getConversationDisplayName(conversation))" size="32">
                                            {{ getConversationDisplayName(conversation) ? getConversationDisplayName(conversation).slice(0, 1) : '' }}
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title class="body-2">
                                                <template v-if="getConversationDisplayName(conversation)">
                                                    {{ getConversationDisplayName(conversation) }}
                                                </template>
                                                <template v-else>
                                                    <i> &lt;Compte Inactif&gt; </i>
                                                </template>
                                            </v-list-item-title>

                                            <v-list-item-subtitle v-if="conversation.message">
                                                <template v-if="conversation.message.isMe">
                                                    Vous:
                                                </template>
                                                {{ conversation.message.content }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <template v-if="conversation.unreadCount > 0">
                                                <v-badge :content="conversation.unreadCount" color="red" inline dot>

                                                </v-badge>
                                            </template>
                                            <template v-else>
                                                <template v-if="getConversationMe(conversation).tag === 1">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn color="orange" @click.stop="toggleTag(conversation)" icon small v-on="on">
                                                                <v-icon small> fas fa-star </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Suivi
                                                    </v-tooltip>
                                                </template>
                                                <template v-else>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn color="grey" @click.stop="toggleTag(conversation)" icon small v-on="on">
                                                                <v-icon small> fas fa-star </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        Non suivi
                                                    </v-tooltip>
                                                </template>
                                            </template>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>

                                <v-card>
                                    <v-card-text>
                                        <template v-if="getConversationDisplayName(conversation)">
                                            {{ getConversationDisplayName(conversation) }}
                                        </template>
                                        <template v-else>
                                            <i> &lt;Compte Inactif&gt; </i>
                                        </template>

                                        <span class="mx-2"> | </span>

                                        Dernier message le

                                        {{ conversation.message.created | toDate() }}
                                    </v-card-text>
                                </v-card>
                            </v-menu>

                            <v-list-item v-if="newRecipients.length > 0" :value="newConversation.id">
                                <v-list-item-avatar class="white--text text-uppercase" :color="getColorByName(getConversationDisplayName(newConversation))" size="32">
                                    {{ getConversationDisplayName(newConversation) ? getConversationDisplayName(newConversation).slice(0, 1) : '' }}
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="body-2">
                                        {{ getConversationDisplayName(newConversation) }}
                                    </v-list-item-title>

                                    <v-list-item-subtitle v-if="newConversation.message">
                                        <template v-if="newConversation.message.isMe">
                                            Vous:
                                        </template>
                                        {{ newConversation.message.content }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-badge v-if="newConversation.unreadCount > 0" :content="newConversation.unreadCount" color="red" inline dot>

                                    </v-badge>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>

                <!-- Messages -->
                <v-col class="d-flex flex-column fill-height">
                    <v-card color="white" flat class="mt-2">
                        <div id="conversation-header" v-if="selectedConversation" class="d-flex align-center pa-2">
                            <v-avatar class="white--text text-uppercase mr-2" :color="getColorByName(currentDisplayName)" size="32">
                                {{ currentDisplayName.slice(0, 1) }}
                            </v-avatar>

                            <span class="font-weight-medium text-h6 mr-2">
                                {{ currentDisplayName }}
                            </span>

                            <template v-if="isUser">
                                <span class="mr-2">
                                    |
                                </span>

                                <v-chip v-for="program of getConversationOtherBuyer(selectedConversation).programs" :key="program.title" class="mr-1" small label>
                                    {{ program.title }} -
                                    <template v-for="product of program.products">
                                        <span :key="product.reference" class="ml-1"> {{ product.reference }}</span>
                                    </template>
                                </v-chip>
                            </template>
                        </div>

                        <v-divider />

                        <v-list class="px-10" ref="messageList" style="overflow-y: scroll" :style="{ 'height': messageContainerHeight }">
                            <v-row v-if="lastMessageReached" no-gutters class="my-4 grey--text text--darken-1" align="center">
                                <v-divider class="mr-4" />
                                Dernier message atteint
                                <v-divider class="ml-4" />
                            </v-row>

                            <Message v-for="(message, i) of messages" :key="message.id" :messages="messages" :message="message" :index="i" @deleted="onMessageDeleted" @updated="onMessageUpdated" @attachmentDeleted="onAttachmentDeleted" />

                            <template v-if="messages.length === 0 && !isMessagesLoading && !isConversationsLoading">
                                <v-row justify="center" align="center" style="height: 200px" no-gutters>
                                    <v-col>
                                        <h3 class="text-center"> Aucun message </h3>
                                    </v-col>
                                </v-row>
                            </template>

                             <template v-if="messages.length === 0 && !isMessagesLoading && isConversationsLoading">
                                <v-row justify="center" align="center" style="height: 200px" no-gutters>
                                    <v-col>
                                        <h3 class="text-center"> Chargement de la messagerie </h3>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-list>

                        <v-divider />

                        <v-row id="message-input-container" v-if="selectedConversationId" justify="center" style="position: relative">
                            <v-col cols="8">
                                <CreateMessageInput @created="onMessageCreated($event)" :conversationId="selectedConversationId" :newRecipients="newRecipients" :firstMessage="messages.length === 0" class="mt-2" />
                            </v-col>

                            <v-overlay v-if="isMessagesLoading" z-index="999" absolute opacity="0.2">
                                <v-progress-circular indeterminate size="64" />
                            </v-overlay>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-container>
                <v-row align="center" class="fill-height">
                    <v-col>
                        <h1 class="text-center grey--text text--darken-2"> La messagerie est désactivée </h1>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>
import Message from '../components/messages/Message.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import CreateMessageInput from '../components/messages/CreateMessageInput.vue';
import LeaveConversationDialog from '../components/messages/LeaveConversationDialog.vue';
import CreateMessageGroupMenuUser from '../components/messages/CreateMessageGroupMenuUser.vue';
import CreateMessageGroupMenuBuyer from '../components/messages/CreateMessageGroupMenuBuyer.vue';

export default {
    name: 'Messages',

    components: {
        Message,
        CreateMessageInput,
        ProgramAutocomplete,
        LeaveConversationDialog,
        CreateMessageGroupMenuUser,
        CreateMessageGroupMenuBuyer
    },

    data: () => ({
        newMessage: '',
        currentDisplayName: '',
        selectedConversationId: null,
        firstConversationFetch: true,
        lastMessageReached: false,
        setNotSeenTimeout: null,

        conversations: [],
        conversationsCount: 0,
        conversationsTotalPages: 1,
        conversationsCurrentPage: 1,
        isConversationsLoading: false,

        messages: [],
        messagesCount: 0,
        messagesTotalPages: 1,
        messagesCurrentPage: 1,
        isMessagesLoading: false,

        newRecipients: [],

        search: {
            programs: null
        },

        messageContainerHeight: '200px'
    }),

    methods: {
        async fetchConversations(groupId, resetPage) {
            try {
                this.isConversationsLoading = true;
                this.setLoading(true);

                if (resetPage) {
                    this.conversationsCurrentPage = 1;
                }

                const query = {
                    limit: 10000,
                    include: 'users',
                    page: this.conversationsCurrentPage
                };

                if (Array.isArray(this.search.programs) && this.search.programs.length > 0) {
                    query.programs = this.search.programs.join(',');
                }

                const { conversations, count, totalPages, err } = await this.repos.messaging.getConversations(query);
                if (err) {
                    throw new Error(err);
                }
                if (resetPage) {
                    this.conversations = conversations;
                } else {
                    this.conversations = this.conversations.concat(conversations);
                }
                this.conversationsCount = count;
                this.conversationsTotalPages = totalPages;

                if (this.conversations.length > 0) {
                    if (groupId) {
                        this.selectedConversationId = groupId;
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la page',
                    type: 'error'
                });
            } finally {
                this.isConversationsLoading = false;
                this.setLoading(false);
            }
        },

        async fetchMessages() {
            try {
                const selectedConversationId = this.selectedConversationId;
                clearTimeout(this.setNotSeenTimeout);
                this.isMessagesLoading = true;

                const query = {
                    page: this.messagesCurrentPage,
                    include: 'attachments'
                };
                const { messages, count, totalPages, err } = await this.repos.messaging.getMessages(this.selectedConversationId, query);
                if (err) {
                    throw new Error(err);
                }

                if (this.selectedConversationId !== selectedConversationId) {
                    return;
                }

                if (messages.length > 0) {
                    messages[messages.length - 1].last = true;
                }

                let lastMessageNotSeen = null;
                for (const message of messages) {
                    if (!lastMessageNotSeen && !message.isMe && !message.seen) {
                        lastMessageNotSeen = message;
                    }
                    this.messages.unshift(message);
                }

                if (lastMessageNotSeen) {
                    this.setNotSeenTimeout = setTimeout(() => {
                        this.setMessageSeen(lastMessageNotSeen);
                    }, 1500);
                }

                this.messagesCount = count;
                this.messagesTotalPages = totalPages;

                if (this.$refs.messageList) {
                    const previousMessagesScrollHeight = this.$refs.messageList.$el.scrollHeight;
                    if (this.firstConversationFetch) {
                        // On scroll en bas de la page lors du premier chargement
                        this.firstConversationFetch = false;
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                this.$refs.messageList.$el.scrollTop = this.$refs.messageList.$el.scrollHeight;
                            });
                        });
                    } else if (messages.length > 0) {
                        // On scroll à la position ou l'on était avant d'avoir rajouter les nouveaux messages
                        this.$nextTick(() => {
                            const now = this.$refs.messageList.$el.scrollHeight - previousMessagesScrollHeight;
                            this.$refs.messageList.$el.scrollTop = now;
                        });
                    }
                }
                this.lastMessageReached = messages.length === 0 && this.messages.length > 0;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement de la page',
                    type: 'error'
                });
            } finally {
                this.isMessagesLoading = false;
            }
        },

        async setMessageSeen(message) {
            try {
                const { success, err } = await this.repos.messaging.setMessageSeen(message.groupId, message.id);
                if (success) {
                    this.fetchConversations(this.selectedConversationId, true);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
            }
        },

        
        async toggleTag(conversation) {
            try {
                const { success, err } = await this.repos.messaging.toggleConversationtTag(conversation.id);
                if (success) {
                    this.fetchConversations(this.selectedConversationId, true);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
            }
        },

        onMessageDeleted(index) {
            this.messages.splice(index, 1);
        },

        onMessageUpdated(data) {
            this.messages[data.index].content = data.content;
        },

        onConversationCreated(data) {
            this.newRecipients = data.recipients;
            this.selectedConversationId = this.newConversation.id;
        },

        async onMessageCreated(message) {
            this.messages = [];
            if (this.selectedConversationId === -1) {
                this.newRecipients = [];
                await this.fetchConversations(message.groupId, false);
            } else {
                this.fetchMessages();
            }
        },

        onAttachmentDeleted(message, i) {
            message.attachments.splice(i, 1);
        },

        getConversationMe(conversation) {
            return conversation.users
                .find((m) => m.isMe);
        },

        getConversationOtherBuyer(conversation) {
            return conversation.users
                .filter((m) => m.type === 'buyer')
                .find((m) => !m.isMe);
        },

        getConversationDisplayName(conversation) {
            return conversation.users
                .filter((m) => !m.isMe)
                .sort((a) => {
                    return a.type === 'buyer' ? -1 : 1;
                })
                .map((m) => {
                    if (m.id === null) {
                        return '';
                    }

                    if (!m.name && !m.firstname) {
                        return '';
                    }
                    return `${m.name ? m.name : ''} ${m.firstname ? m.firstname : ''}`;
                })
                .join(', ');
        },

        computeMessageContainerHeight() {
            const header = document.querySelector('main .v-toolbar');
            const footer = document.querySelector('#footer');
            const messageContainer = document.querySelector('#message-input-container');
            const conversationHeader = document.querySelector('#conversation-header');

            if (!header || !footer || !messageContainer || !conversationHeader) {
                return;
            }

            const rect1 = header.getBoundingClientRect();
            const rect2 = footer.getBoundingClientRect();
            const rect3 = messageContainer.getBoundingClientRect();
            const rect4 = conversationHeader.getBoundingClientRect();

            const height = window.innerHeight - (rect1.height + rect2.height + rect3.height + rect4.height);

            this.messageContainerHeight = `${height}px`;
        }
    },

    computed: {
        selectedConversation() {
            return this.conversations.find((c) => this.selectedConversationId === c.id);
        },

        newConversation() {
            return {
                id: -1,
                created: new Date().toISOString(),
                application: 'clients',
                context: null,
                contextCode: null,
                unreadCount: 0,
                message: null,
                users: this.newRecipients.map((r) => ({
                    id: r.id,
                    type: r.type,
                    firstname: r.name,
                    name: r.firstname,
                    isMe: false
                }))
            };
        }
    },

    watch: {
        selectedConversationId() {
            this.messages = [];
            this.firstConversationFetch = true;
            this.messagesCurrentPage = 1;
            this.fetchMessages();
            if (this.selectedConversationId) {
                this.conversations.forEach((conv) => {
                    if (conv.id === this.selectedConversationId) {
                        this.currentDisplayName = this.getConversationDisplayName(conv);
                    }
                });
            } else {
                this.currentDisplayName = '';
            }
            const hash = `#${this.selectedConversationId}`;
            if (this.$route.hash !== hash) {
                this.$router.push(hash);
            }
            this.$nextTick(() => {
                this.computeMessageContainerHeight();
            });
        }
    },

    created() {
        let conversationId = parseInt(this.$route.hash.split('#').pop());
        if (isNaN(conversationId)) {
            conversationId = null;
        }
        this.fetchConversations(conversationId, false);

        this.$nextTick(() => {
            if (this.$refs.messageList) {
                this.$refs.messageList.$el.addEventListener('scroll', () => {
                    if (this.$refs.messageList.$el.scrollTop === 0 && !this.lastMessageReached) {
                        this.messagesCurrentPage++;
                        this.fetchMessages();
                    }
                });
            }

            if (this.$refs.conversationsList) {
                this.$refs.conversationsList.$el.addEventListener('scroll', () => {
                    const scrollTop = this.$refs.conversationsList.$el.scrollTop;
                    const scrollHeight = this.$refs.conversationsList.$el.scrollHeight;
                    const clientHeight = this.$refs.conversationsList.$el.clientHeight;
                    if (scrollTop + clientHeight >= scrollHeight) {
                        if (this.conversationsCurrentPage < this.conversationsTotalPages) {
                            this.conversationsCurrentPage++;
                            this.fetchConversations(null, false);
                        }
                    }
                });
            }

            this.computeMessageContainerHeight();
            window.addEventListener('resize', () => {
                this.computeMessageContainerHeight();
            });

            if (this.$refs.programAutocomplete) {
                this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
            }
        });
    }
};
</script>
